
.clock-picker__input {
    border: 1px solid gainsboro;
  }
  .clock-picker__input--error, .clock-picker__input--error.clock-picker__input--has-value {
    color: #F44336;
  }
  .clock-picker__input--error .clock-picker__input, .clock-picker__input--error.clock-picker__input--has-value .clock-picker__input {
      border-color: #F44336;
  }
  /* .clock-picker__input--has-value .clock-picker__input {
    border-color: #00E676;
  } */
  .clock-picker__button {
    background: none;
    width: 24px;
    height: 24px;
    display: inline-block;
    border: 0;
    border-radius: 12px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: #757575;
    font-weight: 500;
    font-size: 13px;
  }
  .clock-picker__button:hover {
      background-color: #f5f5f5;
  }
  .clock-picker__button:active {
      background-color: #ededed;
  }
  .clock-picker__button:focus {
      background-color: #e8e8e8;
  }
  .clock-picker__button--active, .clock-picker__button--active:active, .clock-picker__button--active:hover, .clock-picker__button--active:focus {
      background-color: #a48bd1;
      color: #fff;
  }
  .clock-picker__button[disabled] {
      opacity: .4 !important;
      cursor: default;
  }
  .clock-picker__button[disabled], .clock-picker__button[disabled]:active, .clock-picker__button[disabled]:hover, .clock-picker__button[disabled]:focus {
        background-color: #f5f5f5;
        color: #757575;
  }
  
  .clock-picker__dialog {
    display: none;
  }
  .clock-picker__dialog--active {
      display: block;
  }
  .clock-picker__dialog-drop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 200;
  }
  .clock-picker__dialog-body {
      position: fixed;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      overflow: auto;
      width: 320px;
      margin: -235px -160px;
      background-color: #fff;
      z-index: 201;
      transform: scale(1);
      border-radius: 5px;
      overflow: auto;
  }
  .clock-picker__dialog-header {
      padding: 1.5rem 1.5rem;
      font-size: 2rem;
      text-align: center;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }
  .clock-picker__dialog-header span:hover, .clock-picker__dialog-header span:focus {
        cursor: pointer;
  }
  .clock-picker__dialog-content {
      position: relative;
      width: 100%;
      height: 280px;
      margin-top: 24px;
      margin-bottom: 24px;
  }
  .clock-picker__dialog-content .clock-picker__hours,
      .clock-picker__dialog-content .clock-picker__minutes {
        position: absolute;
  }
  .clock-picker__dialog-actions {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      border-top: 1px solid #f5f5f5;
      padding: .5rem;
  }
  .clock-picker__dialog-action {
      padding: 0 1rem;
      margin: 0;
      background: transparent;
      border: 0;
      -ms-flex: 1;
          flex: 1;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      display: inline-block;
      cursor: pointer;
  }
  .clock-picker__dialog-action:hover {
        background-color: #f5f5f5;
  }
  .clock-picker__dialog-action:focus {
        background-color: #ededed;
  }
  .clock-picker__dialog-action:active {
        background-color: #e6e6e6;
  }
  .clock-picker__dialog-action[disabled] {
        background-color: #f5f5f5;
        color: #e6e6e6;
  }
  .clock-picker__dialog-action[disabled]:hover, .clock-picker__dialog-action[disabled]:focus, .clock-picker__dialog-action[disabled]:active {
          cursor: not-allowed;
  }
  .clock-picker__dialog .scale-enter-active,
    .clock-picker__dialog .scale-leave-active,
    .clock-picker__dialog .fade-enter-active,
    .clock-picker__dialog .fade-leave-active {
      transition: all .3s ease;
  }
  .clock-picker__dialog .scale-enter,
    .clock-picker__dialog .scale-leave-active {
      opacity: 0;
      transform: scale(0);
  }
  .clock-picker__dialog .fade-enter,
    .clock-picker__dialog .fade-leave-active {
      opacity: 0;
  }
  
  .clock-picker__hours {
    position: relative;
    width: 100%;
    height: 280px;
  }
  
  .clock-picker__minutes {
    position: relative;
    width: 100%;
    height: 280px;
  }

  .clock-picker-read-only{
      background-color: white !important;
  }
  