.alignCenter{
    text-align: center;
}

.nowrap-text{
    white-space:nowrap;
}

.info-tag-tooltip{
    border:1px solid black;
    border-radius:3px;
    font-size:8px;
    margin-top:-2%;
}

.disabled-option {
    color: #999; /* Define uma cor mais clara para desativar visualmente a opção */
    pointer-events: none; /* Impede eventos de mouse, tornando a opção inativa para interação */
}

.table-center-div{
    width:90% !important;
    margin: 0 auto !important;
}
.paddingAround{
    padding:0 2.5% 2.5% 2.5% !important;
}

table{
    border-collapse: separate;
    border: none;
}

/**
* Para usar este contentAlignBottom, é necessário colocar no elemento pai o parentAlignBottom
*/
.contentAlignBottom{
    align-self: flex-end;
    width:100%;
}
.parentAlignBottom{
    display: flex;
}
.btnAddImuno{
    left: 50%;
    position:absolute;
}
.botaoCentralizado{
    position:absolute;
    top:50%;
    left:50%;
    margin:-25px 0 0 -25px;
}
.negrito{
    font-weight: bold;
}

.marginTopTexto{
    margin-top: 12px;
}

.marginBottomTexto{
    margin-bottom: 12px;
}

.blue{
    background-color: #2196f3!important;
    color: white !important;
}

.required:before {
	color: #FF0000;
    font-size: 15px;
    content: '*';
    margin-right: 5px;
}

.campoObrigatorio {
    font-size: 15px;
    color: #FF0000;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    margin-left: 2px;
}

.conteudoCentralizado {
    margin: 0 auto;
}

.textoCentralizado {
    text-align: center;
}

.textoEsquerda {
    text-align: left;
}

.textoJustificado{
    text-align: justify;
}

.reverterListaOl{
    list-style: revert;
}

.reverterListaUl {
    padding: revert;
}

li, ol {
    list-style: revert;
}

.informarCep {
    color: #28a745;
    margin-left: 5px;
}

.fonteNegrito {
    font-weight: bolder;
}

.fonteSublinhada{
    text-decoration: underline;
}

.fonteReduzida{
    font-size: 13px;
}

.form-group > label {
    position: static;
    font-size: 1rem !important;
}

.margemInferior {
    margin-bottom: 10px !important;
}

.resizeNone {
    resize: none;
 }

 .dropdown-menu-right{
    /* left: 229px !important; */
    left: 15rem !important;
    top: 0px !important;
    position: absolute !important;
    width: 100% !important;
 }

 .dropdown-toggle::after {
    display: inline-block;
    /* margin-left: .255em; */
    right: 10px !important;
    top: 25px !important;
    position: absolute !important;
    vertical-align: .055em !important;
    content: "";
    border-top: .3em solid transparent !important;
    border-right: 0 !important;
    border-bottom: .3em solid transparent !important;
    border-left: .3em solid !important;
}

 .sidebar{
     z-index: 3 !important;
 }

 .dropdown-toggle {
    white-space: normal !important;
}

.brand-text{
    font-size: 1rem !important;
    font-weight: 800 !important;
    text-align: center;
    letter-spacing: .05rem !important;
    color: #fafafa !important;
    text-decoration: none !important;
}

#sidebarToggleTop{
    color: #fafafa !important;
}

.topbar #sidebarToggleTop:hover {
    background-color: #fafafa !important;
    color: #6777ef !important;
}
  
.topbar #sidebarToggleTop:active {
    background-color: #fafafa !important;
}

.submenu-no-toggle{
    left: 15rem !important; 
    top: 0px !important; 
    position: absolute !important; 
    display: none;
}

.sub-submenu-right{
    left: 10.5rem !important; 
    top: 0px !important; 
    position: absolute !important; 
    display: none;
}

.submenu-right{
    left: 7rem !important; 
    top: 0px !important; 
    position: absolute !important; 
    display: none;
}

.modal-bottom {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    max-width: 100% !important;
    margin: 0 !important;
}

.modal-bottom .modal-content {
      border-radius: 0.3rem 0.3rem 0 0 !important;
}

.modal-top {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    max-width: 100% !important;
    margin: 0 !important;
}

.modal-top .modal-content {
      border-radius: 0.3rem 0.3rem 0 0 !important;
}

.modal-right {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    max-width: 100% !important;
    width: 35% !important;
    height: 100% !important;
    margin: 0 !important;
}

.modal-right .modal-content {
      border-radius: 0.3rem 0.3rem 0 0 !important;
      height: 100% !important;
}

.buttons-inline{
    display: block ruby !important;
}

@media (max-width: 768px) {
    .sidebar .nav-item {
        overflow: visible !important;
    }

    .sub-submenu-right{
        left: 10.5rem !important; 
    }
    
    .submenu-right{
        left: 12rem !important; 
    }

    .brand-text{
        font-size: 0.7rem !important;
    }

    .sidebar-brand-icon img{
        height: 3.5rem !important;
    }

    #userDropdown img{
        max-width: 9.5rem !important;
        margin: 0 0 0 -10px !important;
    }

    .modal-right {
        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
    }
}