/**
 * App css 
 */

.required:before {
    color: #FF0000;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 2px;
    margin-right: 2px;
    content: "\2a";
}

/**
    Label do botão de upload
**/
.custom-file-label::after{
    content:"Buscar" !important;
}

.bg-c-regular {
    background-color: #abbac3;
}

.tituloCadastro{
    color: #405467 !important; 
    opacity: 1;
    border-color: #ffeeba;
}

.alinhamentoTexto {
    text-align: justify;
}

.bordaCamposUpload {
    margin-left: 15px;
}

@media (max-width: 768px)
{
    .comboLarguraMaxima
    {
        width: 100% !important;
    }

    .bordaCamposUpload {
        margin-right: 15px;
    }
}

.tituloGrupo {
    text-align: center;
    color: #000;
    margin-left: 15px;
    margin-right: 15px;
    padding: 3px 0 3px 0;
    text-decoration: underline;
}

.mensagemAtencaoGrupo {
    text-align: center;
    font-weight: bolder;
    padding: 10px;
}

.conteudoCentralizado {
    margin: 0 auto !important;
    margin-bottom: 15px !important;
    text-align: center;
}

.alinharEsquerda {
    float: left;
}

.dialogMargemSuperior {
    margin-top: 15px !important;
}

.infoMargemInferior {
    margin-bottom: 15px !important;
}

.form-control::placeholder {
    color: #000;
    opacity: 0.3     !important;
}

.sublinhado {
    text-decoration: underline;
}